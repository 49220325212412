<template>
	<div class="navmb">
		<div class="flex flexa">
			<span class="">Bug反馈</span>
		</div>
	</div>
	<div class="bgf mart20">
		<ul class=" bgf h120 flex col1582">
			<li class="flex1 flex flexa">
				<div class="w100 tc point" :class="{
					colzhuti: param.bugStatus == 1
				}" @click="param.bugStatus =1; getList(1)">
					<p class="font14 padb10">待审核Bug反馈</p>
					<h1 class="font28 fontw6">{{ status.auditNumber ?? '-' }}</h1>
				</div>
			</li>
			<li class="flex1 flex flexa" :class="{
				colzhuti: param.bugStatus == 2
			}" @click="param.bugStatus = 2; getList(1)">
				<el-divider direction="vertical" style="height: 64px; background-color: #E5E5E5" />
				<div class="w100 tc point">
					<p class="font14 padb10">处理中Bug反馈</p>
					<h1 class="font28 fontw6">{{ status.processingNumber ?? '-' }}</h1>
				</div>
			</li>
			<li class="flex1 flex flexa" :class="{
				colzhuti: param.bugStatus == ''
			}" @click="param.bugStatus = ''; getList(1)">
				<el-divider direction="vertical" style="height: 64px; background-color: #E5E5E5" />
				<div class="w100 tc point">
					<p class="font14 padb10">全部Bug反馈</p>
					<h1 class="font28 fontw6">{{ status.allNumber ?? '-' }}</h1>
				</div>
			</li>
		</ul>
	</div>
	<div class="bgf manage1 mart20">
		<div class="padd20 flex flexsb">
			<el-button type="primary" class="w115 h40" @click="$router.push({
						path: '/bug/create'
					})">新建Bug反馈</el-button>
			<div class="m-leftbox">
				<div class="item">
					<div class="g-sel_inp">
						<el-select size="large" @change="param.bugTitle='',param.outTradeNo='',getList(1)" v-model="param.searchKey" placeholder="Bug标题">
							<el-option label="Bug标题" value="bugTitle" />
							<el-option label="关联编号" value="outTradeNo" />
						</el-select>
						<el-input size="large" v-model="param[param.searchKey]" placeholder="搜索关键字">
							<template #suffix>
								<el-icon @click="getList(1)" color="#FF6A00" class="el-input__icon">
									<Search />
								</el-icon>
							</template>
						</el-input>
					</div>
				</div>
				<div class="item">
					<div class="g-sel_inp">
						<el-button size="large" class="w68" style="margin-right: -1px" @click="() => {
							param.time = []
							param.startTime = ''
							param.endTime = ''
							getList(1)
						}">全部</el-button>
						<el-date-picker v-model="param.time" format="YYYY-MM-DD HH:mm" value-format="YYYY-MM-DD HH:mm"
							type="datetimerange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
							size="large" @change="(val) => {
							const [createTime, endTime] = val ?? []
							param.startTime = createTime
							param.endTime = endTime
							getList(1)
						}"></el-date-picker>
					</div>
				</div>

				<div class="opts">
					<div class="refresh">
						<el-icon @click="param={ bugStatus: '',pageNumber: 1,pageSize: 10,searchKey:'bugTitle'},getList(1)">
							<img style="width: 1em;height:1em" draggable="false" src="../../assets/imgs/refersh.png" />
						</el-icon>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="bgf mart20">
		<el-table :data="list" style="width: 100%" v-loading="loading">
            <el-table-column fixed min-width="90" prop="bugNumberCropping" label="BugID">
            </el-table-column>
			<el-table-column fixed label="Bug标题" class-name="padl20 padr20" prop="bugTitle"
				:show-overflow-tooltip="{ effect: 'light' }" min-width="234">
				<template #="{row}">
					<router-link class="bllk block text1 col4c" :to="`/bug/in/${row.id}`">{{ row.bugTitle }}</router-link>
				</template>
			</el-table-column>
			<el-table-column min-width="120" prop="outTradeNo" label="关联SR编号"
				:show-overflow-tooltip="{ effect: 'light' }">
			</el-table-column>
			<el-table-column min-width="120" prop="productName" label="发生产品">
			</el-table-column>
			<el-table-column min-width="120" prop="productModuleName" label="发生模块">
			</el-table-column>
			<el-table-column min-width="120" prop="createTime" label="创建时间">
			</el-table-column>
			<el-table-column min-width="120" prop="updateTime" label="上次更新时间">
			</el-table-column>
			<el-table-column min-width="120" prop="priority" label="Bug等级">
				<template #="{ row }">
					<div class="flex">
						<div
							:style="`width:5px;height:5px;margin:auto 0;border-radius: 50%;background-color: ${priorityC[row.priority]}`">
						</div>
						<p class="marl10">{{ priorityL[row.priority] }}</p>
					</div>
				</template>
			</el-table-column>
			<el-table-column min-width="120" prop="bugStatus" label="状态">
				<template #="{ row }">
					<span>{{ bugStatus.get(row.bugStatus) }}</span>
				</template>
			</el-table-column>
			<el-table-column min-width="150" label="操作">
				<template #="{ row }">
					<span class="point colzhuti font12" @click="$router.push({
						path: `/bug/in/${row.id}`
					})">查看详情</span>
					<el-divider direction="vertical"></el-divider>
					<span class="point colzhuti font12" @click="clickDelete(row)">删除</span>
					<template v-if="row.bugStatus == 3">
						<el-divider direction="vertical"></el-divider>
						<span class="point colzhuti font12" @click="showReason(row)">查看原因</span>
					</template>
				</template>
			</el-table-column>
		</el-table>
		<div class="h70 pad20 flexab w100 borbox">
			<p class="font10 cola4">共 {{ total }} 条</p>
			<el-pagination v-model:currentPage="param.pageNumber" @update:page-size="handleSizeChange"
				@update:current-page="getList" :page-sizes="[10, 15, 20, 25]" :page-size="param.pageSize"
				layout=" sizes, prev , jumper , next" :total="total">
			</el-pagination>
		</div>
	</div>
	<el-dialog :close-on-click-modal="false" v-model="reasonData.show" align-center title="拒绝理由" width="520px">
        <el-form :model="reasonData.info" ref="submitAudit" label-width="auto" :rules="reasonData.rules">
            <el-form-item label="拒绝理由" prop="reason">
                <el-input disabled type="textarea" resize="none" v-model="reasonData.info" />
            </el-form-item>
        </el-form>

        <template #footer>
            <el-button type="primary" @click="reasonData.show=false">确定</el-button>
        </template>
    </el-dialog>
</template>
<script>
import { Search } from '@element-plus/icons-vue'
export default {
	setup() {
		return {
			bugStatus: new Map([
				[1, '待审核'],
				[2, '审核通过'],
				[3, '拒绝'],
				[4, '待接收'],
				[5, '接受/处理'],
				[6, '已解决'],
				[7, '已验证'],
				[8, '已关闭'],
				[9, '重新打开'],
				[10, '已拒绝'],
			])
		}
	},
	components: { Search },
	data() {
		return {
			reasonData:{show:false,info:''},
			param: {
				bugStatus: '',
				pageNumber: 1,
				pageSize: 10,
				searchKey:'bugTitle'
			},
			loading: false,
			list: [],
			total: 0,
			status: {},
			priorityL: { 1: '致命', 2: '严重', 3: '一般', 4: '提示', 5: '建议', },
			priorityC: { 1: '#E1504A', 2: '#FF6A00', 3: '#06B0FF', 4: '#49E8B2', 5: '#49E8B2', },
		}
	},
	methods: {
		showReason(row) {
			this.reasonData.show = true
			this.reasonData.info = row.refusedReason
		},
		async clickDelete(row) {
			await this.$confirm(
				`确认删除"${row.bugTitle}"吗`,
				"信息",
				{
					confirmButtonText: "确定",
					cancelButtonText: "取消",
				}
			)
			const result = await this.$api.bug.BugFeedbackRemoveBug({
				id: row.id,
			})
			this.getList(1)
		},
		async bugstatistics() {
			try {
				this.status = await this.$api.bug.bugstatistics()
			} catch (e) {
				return console.log(e)
			}
		},
		async getList(pageNumber) {
			try {
				pageNumber && (this.param.pageNumber = pageNumber)
				this.loading = true
				const { records, total } = await this.$api.bug.bugFeedback(this.param)
				
				this.list = records
				this.total = total
				console.log(this.list, 'this.list')
			} catch (e) {
				return console.log(e)
			} finally {
				this.loading = false
			}
		},
		handleSizeChange(val) {
			this.param.pageSize = val;
			this.getList();
		},
	},
	mounted() {
		this.getList()
		this.bugstatistics()
	},
}
</script>
<style scoped>
.m-leftbox {
	.item {
		margin-right: 24px;
	}
}

.m-leftbox,
.m-flexbox {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.m-flexbox {
	margin-bottom: 30px;
}

.g-sel_inp {
	display: flex;
	align-items: center;
	--el-border-radius-base: 0;

	.el-select {
		margin-right: -1px;
		width: 120px;
		flex-shrink: 0;
	}

	.el-select__wrapper.is-focused {
		z-index: 2;
	}
}

.m-chotop {
	margin-bottom: 16px;

	.left {
		display: flex;
		align-items: center;
		flex: 1;
	}

	.el-button--primary {
		margin-right: 24px;
	}

	--el-border-radius-base: 0;

	.el-select {
		width: 290px;
	}
}

.opts {
	display: flex;
	align-items: center;
	font-size: 18px;
	color: #888888;
	cursor: pointer;

	.set {
		margin-right: 12px;
	}
}
.el-button:active {
	z-index: 1;
}
</style>